<template>
  <div id="main-wrapper">
    <app-header></app-header>
    <div class="main-content is-header is-footer">
      <!--اسلایدر اصلی-->
      <div style="margin-top:15px;overflow:auto"></div>
      <!--7گزینه-->
      <vcl-Table  v-if="!pageloading" ></vcl-Table>
      <v-container v-else >
        <v-row  class="border-grid pa-5">
          <v-col cols="6" class="d-flex flex-column justify-center align-center" @click="$router.push({path:'/'})">
            <img src="@/assets/demo/brand5.png" width="40px">
            <p class="font-small pt-2 text-center">{{helperExcerpt('اطلاعیه های سایت',18)}}</p>
          </v-col>
          <v-col cols="6" class="d-flex flex-column justify-center align-center" @click="$router.push({path:'/'})">
            <img src="@/assets/demo/brand1.png" width="40px">
            <p class="font-small pt-2 text-center">{{helperExcerpt('پیام های مربوط به من',18)}}</p>
          </v-col>


        </v-row>
      </v-container>

      <!--پرفروشترین ها--->
      <v-container >
        <v-row>
          <v-col >
            <p class="text-center font-medium font-weight-bold pt-3"><v-icon color="black" size="27">mdi-seal</v-icon> آخرین اطلاعیه ها و اخبار مربوط به برنامه </p>
          </v-col>
        </v-row>

        <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
        <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
        <v-card v-else :key="key" v-for="(item,key) in news"
                class="mx-auto"
                max-width="344"
        >
          <v-img
              :src="url1+'uploads/message/'+item.image"
              height="200px"
          ></v-img>


          <v-card-actions >
            <v-btn
                @click="show = !show"
                color="orange lighten-2"
                text
            >
              متن خبر در تاریخ {{(item.dates)}}
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                icon
                @click="show = !show"
            >
              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text style="text-align: justify;">
                {{ item.matn }}
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>

      </v-container>


    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src

import {mapGetters} from 'vuex'
import appHeader from '../views/_header'
import appFooter from '../views/_footer'
import { VclFacebook, VclCode,VclTwitch,VclTable } from 'vue-content-loading';

export default {
  name: 'Home',
  components: {
    appHeader,
    appFooter,
    VclFacebook,
    VclCode,
    VclTable,
    VclTwitch,
  },
  data () {
    return {
      show: true,
      url:'https://sharjtosharj.com/soccerp/',
      url1:'https://sharjtosharj.com/',
      news:[],
      page:1,
      pageloading: false,
    }
  },
  mounted() {
    let self = this;

    self.getNews(0,0);

  },
  computed:{
    ...mapGetters(['baseUrl',"auth"]),
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{

    getNews($catId,$page){
      let self = this;

      this.helperGet('firstpage.aspx',{
        cat_id:$catId,
        page:$page,
        perPage:10,
        orderBy:'created_at|desc',
      }).then(function(r){
        //console.log(r);
        if(r.data.length>0){
          //self.news = r.data.data.data;

          self.news=r.data;
          self.pageloading=true;
        }else{
          self.helperToast({
            text:r.data.msg,
            color:'red',
            show:true,
          })
          self.pageloading=true;

        }
        self.page=self.page+1;
      })
    },
    handleScroll (event) {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
      if (bottomOfWindow) {
        this.getNews(0,this.page);
      }
    },

  },
  watch:{}
}
</script>

<style>
.border-grid .col{
  height: 100px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid .col:nth-child(3n + 0){
  border-left: none;
}
.border-grid .col:nth-last-child(-n + 3){
  border-bottom: none;
}
.border-grid .col p{
  font-weight: 600;
}

.border-grid2 .col{
  height: 300px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid2 .col:nth-child(2n + 0){
  border-left: none;
}
.border-grid2 .col:nth-last-child(-n + 2){
  border-bottom: none;
}
.border-grid2 .col p{
  font-weight: 600;
}
</style>
